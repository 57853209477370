import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from "./serviceWorker";

import ReactGA from "react-ga";

import App from "./App";

const development = process.env.REACT_APP_ENVIRONMENT === "development";

if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://b00bb7966f994714b07d540ec9c74803@sentry1.zsnode.com/16",
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
    });
}

ReactGA.initialize(
    [
        { trackingId: "UA-153066077-1", gaOptions: { name: "default" } }
    ],
    { debug: false, alwaysSendToDefaultTracker: false }
);
ReactGA.pageview(window.location.pathname, ["default"]);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
